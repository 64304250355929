/* @tailwind base;
@tailwind components;
@tailwind utilities; */

@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@layer base {
    html {
        @apply h-full
        bg-gray-100
        text-black
        tracking-tight
    }
    body {
        @apply h-full
        tracking-tight
    }

    input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus, input:-webkit-autofill:active {
        transition: background-color 5000s ease-in-out 0s;
    }
}



@layer components {
    .main_container{
       display:flex;
       height:100%;
       width:100%;
       justify-content:'center';
       align-items:'center';
       padding-top: 4rem;

       @media (min-width: 976px) {
        padding-left: 16rem;
       }

    }
    .chart_container-w {
        @media (min-width: 976px) { 
              width: calc(100% - 2rem);
        }
        width:90%;
    }


    @media (min-width: 768px){
        .history_container {
            left: 6rem !important; 
        }
        .history_container:before {
            left:7.5px !important;
        }
        .history_year{
            left: -6rem !important;
            font-size: 25px !important;
            
        }
        .history_date:before {
            left:-48px !important; 
            
        }
        .history_multiple_li:before { 
            left:0.2rem !important; 
         }
    }

    .history_container {
        position: relative; 
        padding: 0 0 0 48px;
        left:3.5rem;   
        margin-right: 4rem;
    }
    .history_container:before {
        content:""; 
        position:absolute; 
        /* left:7.5px;  */
        left: 25.5px;
        top:0;
        width: 2px; 
        height:94%; 
        background:#ddd;
    }
    .history_year {
        position: absolute;
        font-size: 16px;
        left: -2.5rem;
        
        line-height: 1rem;
        font-weight: bold;
        
    }
    .history_date {
        position: relative; 
        color: theme('colors.gray.400'); line-height: 1;
    }
    .history_date:before {
        content:""; 
        position: absolute;
        left: -30px;
        top:50%; 
        transform: translateY(-50%);
        width: 17px; 
        height: 17px; 
        border-radius: 100%;
        background: theme('colors.gray.500');
        box-sizing: border-box;
    }
    .history_ol {
        padding: 10px 0 34px;  
        width: 94%;
    }

    .history_li {
        font-size: 14px; 
        color:#000; 
        line-height: 29px;  
    }
    .history_multiple_li {
        font-size: 14px; 
        color:#000; 
        line-height: 29px;  
    }

    .history_multiple_li:before {
        content:"";
        position: absolute;
        /* left: 3.3px; */
        
        left: 1.35rem;
        margin-top: 14px;
        transform: translateY(-50%);
        width: 10px; 
        height: 10px; 
        border-radius: 100%;
        background: theme('colors.gray.300');
        box-sizing: border-box;
    }

    /* 데스크탑 언어 설정 */
    .lang_ul {
        font-size: 0.875rem;
        line-height: 1.25rem;
        border-radius: 1.5rem;
        display: flex;
        justify-content: space-around;
        align-items: center;
        flex-direction: column;
        width: 7rem;
        height: 5rem;
        left: 10rem;
        /* left: 14.4rem;  헤더에 Contact 메뉴 있을 때*/

        top: 2.8rem;
        position: absolute;
        background: theme('colors.white.DEFAULT');
        font-weight: 400;
        box-shadow: rgb(174 174 192 / 60%) 1px -0.2rem 2rem;
        opacity: 1;
    }
    
    .lang_ul:after {
        content: "";
        position: absolute;
        top: -0.8rem;
        right: calc(20%);
        margin: 0px auto;
        border-right: 0.7rem solid transparent;
        border-bottom: 0.9rem solid theme('colors.white.DEFAULT');
        border-left: 0.7rem solid transparent;
    }

    .lang_li {
        padding: 2px 10px;
    }

    .lang_li-clicked {
        color:theme('colors.secondary');
        font-weight: 500;
    }

    .lang_li:hover {
        color:theme('colors.white.DEFAULT');
        background-color:theme('colors.secondary');
        border-radius:20px;
        font-weight: 500;
        opacity: 1;
    }
}